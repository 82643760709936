import { React } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import NotFound from "./Pages/404";
import Landing from "./Pages/Landing";
import Home from "./Pages/Home";
import ProtectedRoute from "./components/Landing/ProtectedRoute";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route exact path="/policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/terms">
          <TermsAndConditions />
        </Route>
        <Route exact path="/*">
          <ProtectedRoute component={Home} />
        </Route>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
