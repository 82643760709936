import { useEffect, useRef, useState } from "react";
import "../../Styles/singletask.scss";
import WaveLoading from "../Utils/WaveLoading";
import { BsInfoSquare } from "react-icons/bs";
import { GrInProgress } from "react-icons/gr";
import { BsFillPersonCheckFill } from "react-icons/bs";
import CreateReport from "./CreateReport";
import { useLocation } from "react-router-dom";
import Select from "../Utils/Select";
import Input from "../Utils/Input";

export default function SingleTask(props) {
  const [isLoading, setIsLoading] = useState(null);
  const [data, setData] = useState(null);

  const [refresh, setRefresh] = useState(false);
  const [jdets, setJDets] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [udets, setUDets] = useState(null);
  const [tt, setTT] = useState(null);
  const [ct, setCT] = useState(null);
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  useEffect(() => {
    setIsLoading(true);

    fetch(`/api/requests/${id}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        setJDets(data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    if (jdets) {
      setIsLoading(true);
      fetch(`/api/mobile/${jdets?.UserID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          setUDets(data);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [jdets]);

  useEffect(() => {
    setData(null);
    setIsLoading(true);
    fetch(`/api/tasks/requestid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          setData(data[0]);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/subjects/checks/byjobid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          let total = 0;
          let count = 0;
          data.map((item) => {
            const it = JSON.parse(item.Checks);
            total = total + it.length;
            it.map((i) => {
              if (!i.Status) {
                count += 1;
              }
            });
          });
          setTT(total);
          setCT(count);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    if (data && data.Checking == null) {
      fetch(`/api/tasks/${data.ID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ Checking: false }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          setRefresh(!refresh);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (ct === 0 && tt !== 0) {
        updateStatus(true, false);
      }
    }
  }, [tt, ct]);

  function updateStatus(checking, report) {
    setIsLoading(true);
    fetch(`/api/tasks/${data.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Checking: checking, ReportPreparation: report }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        setRefresh(!refresh);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <div data-aos="fade-left" className="singletask">
        <div className="pcontainer">
          <div className="wcontent">
            <div className="pdiv">
              <h5>
                <BsInfoSquare /> Basic Info
              </h5>
              <div className="details div3equal">
                <div className="u_list">
                  <h3>Job Details</h3>
                  <p>
                    <b>Title: </b> {jdets && jdets?.Title}
                  </p>
                  <p>
                    <b>Description: </b> {jdets && jdets?.Description}
                  </p>
                  <p>
                    <b>Consent Form: </b>{" "}
                    {jdets &&
                      jdets?.Consent?.substring(13, jdets?.Consent?.length)}
                  </p>
                  <p>
                    <b>No of Subjects: </b>
                    {/* {props.item.Clients ? props.item.Clients.length : 0} */}
                  </p>
                </div>
                <div className="u_list">
                  <h3>Client Details</h3>
                  <p>
                    <b>Name: </b> {udets && udets?.Name}
                  </p>
                  <p>
                    <b>Phone: </b> {udets && udets?.Phone}
                  </p>
                  <p>
                    <b>Email: </b> {udets && udets?.Email}
                  </p>
                  <p>
                    <b>Type: </b> {udets && udets?.Organisation}
                  </p>
                </div>
                <div className="div prg u_list">
                  <h3>Progress</h3>
                  <Item txt="Received" status={true} />
                  <Item
                    txt="Assigned to Staff"
                    status={data ? data.Received : null}
                  />
                  <Item
                    txt="Actual Checks"
                    status={data ? data.Checking : null}
                    tally={
                      tt && ct
                        ? ` ${tt - ct}/${tt} --- ${(
                            ((tt - ct) / tt) *
                            100
                          ).toFixed(0)}%`
                        : null
                    }
                  />
                  <Item
                    txt="Report Preparation"
                    status={data ? data.ReportPreparation : null}
                  />
                  <Item
                    txt="Report Submission"
                    status={data ? data.ReportSubmitted : null}
                  />
                </div>
              </div>
            </div>

            <div className="pdiv">
              <SubjectsPopup
                JobID={id}
                UserID={udets?.UserID}
                RequestID={jdets?.DocumentID}
              />
            </div>

            {isLoading && <WaveLoading />}
          </div>
        </div>
      </div>
    </>
  );
}

const Item = (props) => {
  const [color, setColor] = useState("red");

  useEffect(() => {
    switch (props.status) {
      case true:
        setColor("green");
        break;
      case false:
        setColor("orange");
        break;
      case null:
        setColor("red");
        break;
      default:
        setColor("red");
        break;
    }
  }, [props.status]);

  return (
    <div className="item">
      <div style={{ backgroundColor: color }}></div>
      <p>
        {props.txt} <b>{props.tally ? props.tally : ""}</b>
      </p>
    </div>
  );
};

const SubjectsPopup = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const [data, setData] = useState(null);
  const [active, setActive] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [showing, setShowing] = useState(null);
  const [report, setReport] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/subjects/byjobid/${props.JobID}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);

        if (data.length > 0) {
          const y = data;
          let d = [];
          y.map((item) => {
            let x = item;
            x.Checks = JSON.parse(item.Checks);
            d.push(x);
          });
          setData(d);
          console.log(d);
          let status = true;
          d[active].Checks.map((e) => {
            if (e.Status.toString() == "false") {
              status = false;
            }
          });
          console.log(status);
          if (status) {
            setReport(true);
          } else setReport(null);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [props.JobID, refresh, active]);

  const UserList = (params) => {
    return (
      <div
        onClick={() => {
          setActive(params.index);
        }}
        style={{
          backgroundColor: active === params.index ? "#FFF5B8" : "white",
        }}
        className="u_list"
      >
        <div className="no">
          <p>{params.index + 1}</p>
        </div>

        <div className="div2equal">
          <p>
            <b>Name: </b>
            {params.item.Name}
          </p>
          <p>
            <b>ID/Passport: </b>
            {params.item.IDNo}
          </p>
        </div>
      </div>
    );
  };

  const Link = (params) => {
    const txt = params.link.slice(13);

    function getDocument() {
      setIsLoading(true);
      fetch(`/api/uploads/${params.link}`)
        .then((res) => {
          if (res.ok) {
            return res.blob();
          } else throw Error("");
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
          setIsLoading(false);
        })
        .catch((e) => {
          alert("File not found!");
          setIsLoading(false);
        });
    }

    return (
      <p
        onClick={() => {
          getDocument();
        }}
        className="link"
      >
        {params.index + 1}. {txt}
      </p>
    );
  };

  const SingleCheck = (params) => {
    return (
      <>
        <div className="p_check">
          <div
            style={{ backgroundColor: params.item.Status ? "green" : "red" }}
          ></div>
          <p>{params.item.Name}</p>
        </div>
      </>
    );
  };

  function getReport(link) {
    setIsLoading(true);
    fetch(`/api/uploads/${link}`)
      .then((res) => {
        if (res.ok) {
          return res.blob();
        } else throw Error("");
      })
      .then((blob) => {
        setIsLoading(false);
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      })
      .catch((e) => {
        setIsLoading(false);
        alert("File not found!");
      });
  }

  return (
    <div data-aos="fade-left" className="tasks">
      <div className="pcontainer">
        <div className="wcontent">
          <div className="div2equal">
            <div className="u_list">
              <h5>
                <BsInfoSquare /> Subject(s) List
              </h5>
              <div className="details">
                {data &&
                  data.length > 0 &&
                  data.map((item, i) => {
                    return <UserList key={i} item={item} index={i} />;
                  })}
              </div>
            </div>
            <div className="u_list">
              <h5>
                <GrInProgress /> Subject Details
              </h5>
              {data && data.length > 0 && (
                <div>
                  <h5>Basic Details</h5>
                  <p>
                    <b>Name: </b>
                    {data[active].Name}
                  </p>
                  <p>
                    <b>Phone: </b>
                    {data[active].Phone}
                  </p>
                  <p>
                    <b>ID/Passport No.: </b>
                    {data[active].IDNo}
                  </p>
                  <br />
                  <h5>Documents</h5>
                  <hr />
                  <p>
                    <b>National ID / Passport</b>
                  </p>
                  <Link index={0} link={data[active].NationalID} />
                  <p>
                    <b>Other Documents</b>
                  </p>
                  {data[active].SubjectFiles.map((item, i) => {
                    return <Link key={i} index={i} link={item} />;
                  })}
                  <br />
                  <h5>Checks</h5>
                  {data[active]?.Checks?.map((item, i) => {
                    return (
                      <SingleCheck
                        key={i}
                        index={i}
                        item={item}
                        id={data[active].ID}
                        setRefresh={setRefresh}
                        refresh={refresh}
                      />
                    );
                  })}
                </div>
              )}
              <div className="pdiv">
                <h5>
                  <BsFillPersonCheckFill /> Report
                </h5>

                {data && (
                  <div className="u_list">
                    <h3>Report</h3>
                    {data[active].Report != null ? (
                      <>
                        <p>
                          Report generated on:{" "}
                          {data[active].updatedAt.split("T")[0]}{" "}
                          {data[active].updatedAt.split("T")[1].substring(0, 5)}
                        </p>

                        <button
                          onClick={() => {
                            getReport(data[active].Report);
                          }}
                        >
                          View Report
                        </button>
                      </>
                    ) : (
                      <>
                        <p>Background checking is not complete</p>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {isLoading && <WaveLoading />}
        </div>
      </div>
      {showing && (
        <CreateReport
          refresh={refresh}
          setRefresh={setRefresh}
          setShowing={setShowing}
          SubjectID={data[active].ID}
          RequestID={props.RequestID}
          UserID={props.UserID}
        />
      )}
    </div>
  );
};
